import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";
import apAxios, { setAuthToken } from "../Utils/apAxios";
import * as Survey from "../Contexts/SurveyContext";
import _ from "lodash";
import ApiAdapter from "../ApiAdapter";

const SSO_ROUTE_MAP = [
  {
    source: "cdp",
    channel: "survey",
    module: "survey",
    page_route: "/",
    cdp_menu1_name: null,
    cdp_menu2_name: "所有問卷",
    page_name: "所有問卷",
  },
  {
    source: "cdp",
    channel: "survey",
    module: "survey-mine",
    page_route: "/?category=my",
    cdp_menu1_name: null,
    cdp_menu2_name: "我的問卷",
    page_name: "我的問卷",
  },
  {
    source: "cdp",
    channel: "survey",
    module: "survey-shared",
    page_route: "/?category=shared",
    cdp_menu1_name: null,
    cdp_menu2_name: "與我分享的問卷",
    page_name: "與我分享的問卷",
  },
  {
    source: "cdp",
    channel: "survey",
    module: "survey",
    page_route: "/",
    cdp_menu1_name: null,
    cdp_menu2_name: "我的調查問卷",
    page_name: "我的調查問卷",
  },
  {
    source: "cdp",
    channel: "survey",
    module: "template",
    page_route: "/templates/",
    cdp_menu1_name: null,
    cdp_menu2_name: "問卷範本",
    page_name: "問卷範本",
  },
  {
    source: "cdp",
    channel: "survey",
    module: "privacy",
    page_route: "/privacy/",
    cdp_menu1_name: null,
    cdp_menu2_name: "個資聲明內文版本",
    page_name: "個資聲明內文版本",
  },
];

let windowGlobal = typeof window !== "undefined" && window;

const Auth = ({ appActions }) => {
  const search = window.location.search;
  const [isLoading, setIsLoading] = useState(true);
  const [authTitle, setAuthTitle] = useState("");
  const [authMessage, setAuthMessage] = useState("");

  const getQueryParams = () => {
    return Object.fromEntries(new URLSearchParams(search));
  };

  const checkToken = async () => {
    const queryParams = getQueryParams();
    console.debug("queryParams:", queryParams);
    const source = _.get(queryParams, "source", "cdp");
    const channel = _.get(queryParams, "channel", "survey");
    const module = _.get(queryParams, "module", "survey");
    const token = _.get(queryParams, "t");
    // TODO 顯示驗證錯誤的訊息
    if (!token) {
      setIsLoading(false);
      navigate("/unauthenticated");
    }

    try {
      setAuthTitle("認證中");
      setAuthMessage("請稍候...");
      const responseAuth = await apAxios.post("/api/v1/mgt/auth/cdp", {
        channel,
        cdp_sso_token: token,
      });

      console.debug("responseAuth:", responseAuth);
      const userInfo = _.get(responseAuth, "data.data");
      const apAccessToken = _.get(userInfo, "Token");
      setAuthToken(apAccessToken);
      await appActions.setToken(apAccessToken);
      // 這邊要相容原本的APP登入資訊，大小寫問題
      await appActions.setProfile({
        ...userInfo,
        // 以下補充原本的APP登入資訊
        RoleId: _.get(userInfo, "RoleId"),
        UserCode: _.get(userInfo, "UserCode"),
        role: _.get(userInfo, "RoleId") === 1 ? "admin" : "user",
        token: _.get(userInfo, "Token"),
        userId: _.get(userInfo, "UserId"),
        userName: _.get(userInfo, "UserName"),
      });
      // 使用source channel module找出對應的路由
      const route = _.find(SSO_ROUTE_MAP, { source, channel, module });
      console.debug("route:", route);
      if (!route) {
        setIsLoading(false);
        navigate("/unauthenticated");
      }
      navigate(route.page_route);

      // return userInfo;
    } catch (error) {
      console.debug("error:", error);
      navigate("/unauthenticated");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * SSO執行驗證:進入點
   */
  useEffect(() => {
    // await appActions.logout();
    checkToken();
  }, []);

  return (
    <div>
      <h2>
        <Spin spinning={isLoading} />
      </h2>
      {!!authTitle && <h3>{authTitle}</h3>}
      {!!authMessage && <p>{authMessage}</p>}
    </div>
  );
};

export default withPage(
  connect(
    (state, ownProps) => ({
      // profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(Auth)
);
